<script>
import Vue from 'vue'
import { validationMixin } from 'vuelidate'

export default Vue.extend({
    name: 'CostRangesMixin',
    mixins: [validationMixin],
    data () {
        return {
            popupActive: true,
            quantityInput: 50,
            ranges: [],
            isCancel: false,
        }
    },

    methods: {
        onCancel () {
            this.isCancel = true
            this.params.stopEditing(true)
        },
        onDone () {
            this.isCancel = false
            this.params.stopEditing()
        },
        addRange (index) {
            let prevRange = this.ranges[index - 1]
            let prevEnd = prevRange ? prevRange.end : null
            let newStart
            if (index === this.ranges.length && prevEnd) {
                newStart = prevEnd
            } else {
                newStart = prevRange.start + 1 || null
            }

            this.ranges.splice(index, 0, {
                start: newStart,
                end: null,
                value: null,
            })
        },
        removeRange (index) {
            this.ranges.splice(index, 1)
        },
        isPopup () {
            return true
        },

        isCancelAfterEnd () {
            return this.isCancel
        },

        endValueForRangeIndex (index) {
            const nextIndex = parseInt(index) + 1

            return parseInt(this.ranges[nextIndex].start) - 1
        },
        convertRawToRanges ({ costSteps, costIncrements }) {
            costSteps.forEach((costStepStart, key) => {
                if (key === costSteps.length - 1) {
                    return
                }

                const costStepEnd = costSteps[key + 1] - (key === costSteps.length - 2 ? 0 : 1)
                const costIncrement = costIncrements[key]

                this.ranges.push({
                    start: Number(costStepStart),
                    end: Number(costStepEnd),
                    value: costIncrement,
                })
            })
        },
    },
    computed: {
        costSteps () {
            if (this.ranges.length === 0) {
                return []
            }
            const costSteps = this.ranges.map(range => parseInt(range.start))
            costSteps.push(this.ranges[this.ranges.length - 1].end)

            return costSteps
        },
        costIncrement () {
            if (this.ranges.length === 0) {
                return []
            }
            const costIncrements = this.ranges.map(range => Number(range.value))
            costIncrements.unshift(this.ranges[0].value)
            return costIncrements
        },
    },

    watch: {
        ranges: {
            handler () {
                this.ranges.forEach((range, key) => {
                    if (key === this.ranges.length - 1) {
                        return
                    }

                    range.end = this.ranges[parseInt(key) + 1].start - 1
                })
            },
            deep: true,
        },
    },
})
</script>

<style scoped lang="scss">
    .cost-price-edit {
        background-color: #f7f7f7;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 20px;
    }

    .add-range {
        text-align: center;
        opacity: 0.4;
        transition: all 150ms;
        cursor: pointer;
        transform: scale(0.4);
        z-index: 2;
        position: relative;
        margin-top: -10px;
        margin-bottom: -10px;
        filter: grayscale(100%);

        &:hover {
            opacity: 1;
            transform: scale(1);
            filter: grayscale(0%);
        }
    }

    .sidecard {
        margin-bottom: 10px;
    }
</style>
