<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getProductVariationsBySupplierIdAndProductHash')" class="full-height-layout fill product-variations-view">
        <ag-grid-vue
                class="ag-grid fill ag-theme-material border" id="myGrid"
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                @model-updated="onModelUpdated"
                @selection-changed="onSelectionChanged"
                @cell-value-changed="onCellValueChanged"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :debug="debug"
                rowSelection="multiple"
                :enableRangeSelection="true"
                rowModelType="serverSide"
                :paginationPageSize="20"
                :pagination="true"
                :getRowNodeId="getRowNodeId"
                :suppressCellSelection="true"
                :suppressRowClickSelection="true"
                :stopEditingWhenGridLosesFocus="true"
        ></ag-grid-vue>

        <mercur-dialog :is-open.sync="showAvailabilityPopup" class="dialog-old">
            <h3 class="mt-0">Change status for {{selectedRows.length}} variation<span
                    v-if="selectedRows.length > 1">s</span>
            </h3>
            <form @submit.prevent="submitBulkChange">
                <div v-if="bulkEditParams">
                    <mercur-select v-model="bulkEditParams.status" required>
                        <template slot="label">New status</template>
                        <option
                            v-for="(variationStatus, key) in variationStatusses"
                            :key="key"
                            :value="variationStatus.value"
                            >{{variationStatus.title}}
                        </option>
                    </mercur-select>

                </div>
                <div class="position-relative pb-4">
                    <mercur-progress-bar indeterminate v-if="loading"/>
                </div>
                <div class="col-12 text-right mt-4">
                    <mercur-button class="btn btn-raised" @click="showAvailabilityPopup = false">Cancel</mercur-button>
                    <mercur-button type="submit" :disabled="loading"
                               class="btn btn-raised btn-yellow">Change
                    </mercur-button>
                </div>
            </form>

        </mercur-dialog>
        <portal to="actionbar">
            <transition name="slide">
                <div v-if="selectedRows.length" class="card-padding">
                    <mercur-button class="btn btn-raised btn-yellow" @click="openAvailabilityPopup">Change availability
                    </mercur-button>
                </div>
            </transition>
        </portal>

    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>

import CONFIG from '@root/config'

import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'
import CostPriceEdit from '@/components/CostPriceEdit'
import { commaStringValueSetter } from '@/components/utils/AgGridUtils'
import { commaStringToArray } from '@/components/utils/Utils'
import { debounce } from 'debounce'
import collect from 'collect.js'

export default {
    name: 'ProductVariationsView',

    components: {
        'ag-grid-vue': AgGridVue,
    },
    data () {
        return {
            gridOptions: null,
            gridApi: null,
            columnApi: null,
            columnDefs: null,
            defaultColDef: null,
            getRowNodeId: null,
            serverSideDatasource: null,
            url: null,
            selectedRows: [],
            showAvailabilityPopup: false,
            loading: false,
            bulkEditParams: null,
            changedData: {},
            costColumnIds: ['costSteps', 'costIncrement', 'unitCost'],
            productionDayFields: ['costSteps', 'discounts'],
        }
    },
    beforeMount () {
        this.gridOptions = {
            groupHeaderHeight: 50,
            headerHeight: 50,
            rowHeight: 50,
        }
        this.columnDefs = [
            {
                pinned: 'left',
                headerName: 'Mercur sku',
                field: 'mercurSku',
                editable: false,
                checkboxSelection: true,
                cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
            },
            {
                headerName: 'Supplier sku',
                field: 'supplierSku',
            },
            {
                headerName: 'Facility',
                field: 'facilityName',
                editable: false,
            },
            {
                headerName: 'Costs',
                headerClass: ['ag-grid__header-cell', 'ag-grid__header-cell--border-right', 'ag-grid__header-cell--border-left', 'align-center'],
                children: [
                    {
                        headerName: 'Unit cost',
                        field: 'unitCost',
                        headerClass: ['ag-grid__header-cell', 'ag-grid__header-cell--border-left'],
                        cellClass: ['ag-grid__cell--border-left'],
                        valueSetter: params => {
                            params.data[params.colDef.field] = Number(params.newValue)
                        },
                    },
                    {
                        headerName: 'Cost steps',
                        field: 'costSteps',
                        valueSetter: commaStringValueSetter,
                    },
                    {
                        headerName: 'Cost increment',
                        field: 'costIncrement',
                        valueSetter: commaStringValueSetter,
                    },
                    {
                        headerName: '',
                        colId: 'costs',
                        width: 100,
                        singleClickEdit: true,
                        headerClass: ['ag-grid__header-cell', 'ag-grid__header-cell--border-right'],
                        cellClass: ['ag-grid__cell--strong', 'ag-grid__cell--border-right', 'ag-grid__cell--pointer'],

                        valueSetter: params => {
                            this.costColumnIds.forEach(colId => {
                                params.data[colId] = params.newValue[colId]

                                this.handleCellChanges({
                                    newValue: params.newValue[colId],
                                    colId,
                                    nodeId: params.node.id,
                                    node: params.node,
                                })
                            })

                            return true
                        },
                        valueFormatter () {
                            return 'change'
                        },

                        cellEditorFramework: CostPriceEdit,
                        suppressKeyboardEvent ({ editing }) {
                            return editing
                        },
                    },
                ],
            },
        ]

        this.defaultColDef = {
            resizable: true,
            sortable: true,
            editable: true,
            cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
        }

        this.getRowNodeId = data => {
            return data.mercurSku
        }
    },
    computed: {
        variationStatusses () {
            return CONFIG.STATUSSES.SUPPLIERS.VARIATIONS.VARIATION
        },
    },
    created () {
        this.url = CONFIG.API.ROUTES.PRODUCTS.VARIATIONS.GET.replace('{supplierId}', this.supplierId).replace('{productHash}', this.$route.params.productHash)
    },
    mounted () {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi
    },

    methods: {
        handleRows (rows) {
            const productionDays = Object.keys(rows[0].productionDays)
            const currentProductionDays = this.columnDefs.map(col => col.productionDayCount)
            const columnsToAdd = collect(productionDays).diff(currentProductionDays).all()

            columnsToAdd.forEach(column => {
                this.columnDefs.push({
                    headerName: `${column} production day`.concat(column === '1' ? '' : 's'),
                    headerClass: 'ag-grid__header-cell--border-right',
                    children: [{
                        field: `productionDays.${column}.costSteps`,
                        headerName: 'Steps',
                        propertyName: 'costSteps',
                        productionDay: column,
                        valueSetter: function (params) {
                            params.data.productionDays[params.colDef.productionDay][params.colDef.propertyName] = commaStringToArray(params.newValue).map(value => Number(value))
                        },
                    }, {
                        field: `productionDays.${column}.discounts`,
                        headerName: 'Discounts',
                        propertyName: 'discounts',
                        productionDay: column,
                        valueSetter: function (params) {
                            params.data.productionDays[params.colDef.productionDay][params.colDef.propertyName] = commaStringToArray(params.newValue).map(value => Number(value))
                        },
                    }, {
                        headerName: '',
                        colId: `costs_${column}`,
                        productionDay: column,
                        width: 100,
                        singleClickEdit: true,
                        headerClass: 'ag-grid__header-cell--border-right',
                        cellClass: ['ag-grid__cell--strong', 'ag-grid__cell--border-right', 'ag-grid__cell--pointer'],

                        valueSetter: params => {
                            params.data.productionDays[params.newValue.days] = params.newValue

                            this.handleCellChanges({
                                newValue: params.newValue,
                                colId: 'productionDays',
                                nodeId: params.node.id,
                                node: params.node,
                            })

                            return true
                        },
                        valueFormatter () {
                            return 'change'
                        },
                        suppressKeyboardEvent ({ editing }) {
                            return editing
                        },

                    }],
                })
            })
        },
        submitBulkChange () {
            this.submitData(this.bulkEditParams)
        },

        submitRowChanges: debounce(function () {
            this.submitData(this.changedData)
            this.changedData = {}
        }, 500),

        submitData (data) {
            const url = CONFIG.API.ROUTES.PRODUCTS.VARIATIONS.UPDATE_BULK
            const payload = {
                supplierId: this.supplierId,
                productHash: this.$route.params.productHash,
                skus: data,
            }
            const jobName = JSON.stringify([url, payload])
            this.loading = true
            this.addJob(jobName)

            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Variations successfully updated`,
                })
                this.showAvailabilityPopup = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Updating variations failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
        openAvailabilityPopup () {
            this.bulkEditParams = {
                status: null,
                mercurSkus: this.selectedRows.flatMap(sku => sku.mercurSku),
            }
            this.showAvailabilityPopup = true
        },
        onSelectionChanged () {
            this.selectedRows = this.gridApi.getSelectedRows()
        },
        handleCellChanges (params) {
            const { newValue, nodeId, colId, node } = params

            if (!this.changedData[nodeId]) {
                this.changedData[nodeId] = {}
            }

            const columnPath = colId.split('.')
            const facilityId = node.data.facilityId

            if (columnPath[0] === 'productionDays') {
                const days = columnPath[1]
                if (!this.changedData[nodeId]['productionDays']) {
                    this.changedData[nodeId]['productionDays'] = []
                }

                let storedProductionDays = this.changedData[nodeId]['productionDays'].find(productionDayCosts => productionDayCosts.days === days && productionDayCosts.facilityId === facilityId)
                const localProductionDays = node.data.productionDays[days]

                if (!storedProductionDays) {
                    const newDay = {
                        ...localProductionDays,
                        days,
                        facilityId,
                    }
                    this.changedData[nodeId]['productionDays'].push(newDay)
                }

                const storedProductionDaysIndex = this.changedData[nodeId]['productionDays'].findIndex(productionDayCosts => productionDayCosts.days === days && productionDayCosts.facilityId === facilityId)

                this.changedData[nodeId]['productionDays'][storedProductionDaysIndex][columnPath[2]] = newValue
                this.changedData[nodeId]['productionDays'][storedProductionDaysIndex].from = this.changedData[nodeId]['productionDays'][storedProductionDaysIndex].costSteps.slice(1)[0]
                this.changedData[nodeId]['productionDays'][storedProductionDaysIndex].to = this.changedData[nodeId]['productionDays'][storedProductionDaysIndex].costSteps.slice(-1)[0]
                this.changedData[nodeId]['productionDays'][storedProductionDaysIndex].discount = this.changedData[nodeId]['productionDays'][storedProductionDaysIndex].discounts
            } else if (['costSteps', 'costIncrement', 'unitCost'].includes(columnPath[0])) {
                if (!this.changedData[nodeId]['productFacilityCost']) {
                    this.changedData[nodeId]['productFacilityCost'] = {
                        facilityId,
                        unitCost: node.data.unitCost,
                        costSteps: node.data.costSteps,
                        costIncrement: node.data.costIncrement,
                    }
                }
            } else {
                this.changedData[nodeId][colId] = newValue
            }

            this.submitRowChanges()
        },
        onCellValueChanged (params) {
            if (JSON.stringify(params.oldValue) === JSON.stringify(params.newValue)) {
                return
            }
            const nodeId = params.node.id
            const colId = params.column.colId

            this.handleCellChanges({
                newValue: params.newValue,
                nodeId,
                colId,
                node: params.node,
            })
        },
        onGridReady (params) {
            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: this.url,
                defaultSortBy: 'mercur_sku',
                defaultSortType: 'ASC',
                params: {
                    supplierId: this.supplierId,
                    productHash: this.$route.params.productHash,
                },
                responseHandler: (successCallback, data) => {
                    let total = 0
                    if (data.data.items.length > 0) {
                        total = data.data.items[0].total
                    }
                    successCallback(data.data.items, total)
                    this.handleRows(data.data.items)
                },
            })

            params.api.setServerSideDatasource(this.serverSideDatasource)
        },
        onModelUpdated (params) {
            params.columnApi.autoSizeColumn('supplierSku')
            params.columnApi.autoSizeColumn('unitCost')
            params.columnApi.autoSizeColumn('costs')
        },
    },
}
</script>

<style scoped lang="scss">
    .ag-grid {
        height: 100%;
        width: 100%;
    }
</style>
