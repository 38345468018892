<template>
    <mercur-dialog width="900px" :is-open.sync="popupActive">
        <div class="cost-price-edit fill">
            <div class="container">
                <div class="row">
                    <div class="col col-4">
                        <div class="sidecard border-right">
                            <h2 class="font-weight-normal">Unit cost</h2>
                            <div class="pr-3 mb-4">
                                <mercur-input v-model.number="$v.unitCost.$model" type="number" step=".01">
                                    Unit cost
                                    <template slot="note">
                                        <span class="form-error" v-if="!$v.unitCost.required">Required</span>
                                    </template>
                                </mercur-input>
                            </div>
                            <h3 class="font-weight-normal pt-4">Check your cost</h3>
                            <div class="pr-3">
                                <mercur-input v-model="quantityInput" type="number">
                                    Quantity
                                </mercur-input>
                                <p>For quantity <em>{{quantityInput}}</em> the cost will be <strong :class="{'error': resultPrice < 0}">{{resultPrice | asMoney}}</strong></p>
                                <p><strong :class="{'error': resultPrice < 0}">{{(resultPrice / quantityInput) | asMoney}}</strong><em> per piece</em></p>
                            </div>
                        </div>
                    </div>
                    <div class="col col-8">
                        <div class="range" v-for="(v, key) in $v.ranges.$each.$iter" :key="key">
                            <mercur-card class="my-3">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-4">
                                            <mercur-input v-model.number="v.start.$model" type="number">
                                                From
                                                <template slot="note">
                                                    <span v-if="!v.start.required" class="form-error">Required</span>
                                                    <span v-else-if="!v.start.lessThanOrEqualReference" class="form-error">Must be equal to or less than the <em>To</em> value ({{v.end.$model}})</span>
                                                </template>
                                            </mercur-input>
                                        </div>
                                        <div class="col-4">
                                            <mercur-input v-if="Number(key) === ranges.length - 1" v-model.number="v.end.$model" type="number">
                                                To
                                            </mercur-input>
                                            <mercur-input v-else :value="endValueForRangeIndex(key)" disabled readonly>
                                                To
                                            </mercur-input>
                                        </div>
                                        <div class="col-4">
                                            <mercur-input v-model="v.value.$model" type="number" step=".1">
                                                Cost increment
                                                <template slot="note">
                                                    <span v-if="!v.value.required" class="form-error">Required</span>
                                                </template>
                                            </mercur-input>
                                        </div>
                                        <div class="col-12 text-right">
                                            <mercur-button class="btn btn-yellow btn-icon btn-icon-sm" @click="addRange(Number(key) + 1)">
                                                <i class="fas fa-plus"></i>
                                            </mercur-button>
                                            <mercur-button class="btn btn-red btn-icon btn-icon-sm text-white" @click="removeRange(key)">
                                                <i class="fas fa-trash"></i>
                                            </mercur-button>
                                        </div>
                                    </div>
                                </div>
                            </mercur-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <mercur-button class="btn" @click="onCancel">
                Cancel
            </mercur-button>
            <mercur-button class="btn btn-yellow" :disabled="$v.$invalid || $v.$anyDirty === false" @click="onDone">
                Save
            </mercur-button>
        </div>
    </mercur-dialog>
</template>

<script>
import Vue from 'vue'

import {
    required,
    minLength,
} from 'vuelidate/lib/validators'
import {
    lessThanOrEqualReference,
} from '@/components/utils/ValidationUtils'
import CostRangesMixin from '@/components/CostRangesMixin'

export default Vue.extend({
    name: 'CostPriceEdit',
    mixins: [CostRangesMixin],
    data () {
        return {
            unitCost: 0,
        }
    },
    validations: {
        unitCost: {
            required,
        },
        ranges: {
            required,
            minLength: minLength(1),
            $each: {
                start: {
                    required,
                    lessThanOrEqualReference: lessThanOrEqualReference('end'),
                },
                end: {},
                value: {
                    required,
                },
            },
        },
    },
    methods: {
        getValue () {
            return {
                costIncrement: JSON.parse(JSON.stringify(this.costIncrement)),
                costSteps: JSON.parse(JSON.stringify(this.costSteps)),
                unitCost: this.unitCost,
            }
        },
    },
    computed: {
        resultPrice () {
            let index = this.costSteps.findIndex(step => this.quantityInput < step)

            if (index === -1) {
                index = this.costSteps.length - 1
            }

            const increment = this.costIncrement[index]

            return (Number(this.unitCost) + Number(increment)) * Number(this.quantityInput)
        },
    },

    created () {
        this.unitCost = this.params.node.data.unitCost || 1
        this.convertRawToRanges({
            costSteps: this.params.node.data.costSteps || [100],
            costIncrements: this.params.node.data.costIncrement.slice(1) || [1],
        })
    },
})
</script>
