class ApiServerSideDataSource {
    constructor ({ $api, url, params, responseHandler, getFilterModel }) {
        this.$api = $api
        this.params = params
        this.url = url
        this.responseHandler = responseHandler
        this.getFilterModel = getFilterModel
    }

    getRows ({ request, successCallback, failCallback }) {
        const payload = { request }
        if (this.params) {
            payload.params = this.params
        }
        this.$api.post(this.url, payload).then(({ data }) => {
            this.responseHandler(successCallback, data)
        }).catch((error) => {
            failCallback(error)
        })
    }
}

export default ApiServerSideDataSource
